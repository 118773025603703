<template>
  <div>
    <b-modal
      id="editFastAddressCategory"
      title="Изменить"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @shown="getDataById()"
    >
      <b-row>
        <b-col cols="12">
          <label for="">Наименование</label>
          <b-input v-model="category.name" placeholder="Наименование" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-1" cols="12">
          <b-form-file
            browse-text="Изменить иконку"
            v-model="category.icon"
            accept=".svg"
            @change="uploadFile($event, 'iconSrc')"
            placeholder="Выберите файл или перетащите его сюда..."
          >
          </b-form-file>
        </b-col>
      </b-row>
      <b-row class="justify-content-center my-1">
        <img style="width: 100px" v-if="iconSrc" :src="iconSrc" />
      </b-row>
      <b-row>
        <b-col class="mt-1" cols="12">
          <b-form-file
             browse-text="Изменить иконку для карты"
            v-model="category.icon_by_map"
            accept=".svg"
            @change="uploadFile($event, 'imageSrc')"
            placeholder="Выберите файл или перетащите его сюда..."
          >
          </b-form-file>
        </b-col>
      </b-row>
      <b-row class="justify-content-center my-1">
        <img style="width: 100px" v-if="imageSrc" :src="imageSrc" />
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-1">
          <b-form-checkbox class="mt-2" v-model="category.is_active" checked="true" name="check-button" switch inline>
            {{this.category.is_active ? 'Активный' : ' Не активный'}}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col class="d-flex justify-content-end">
          <b-button :disabled="submitButtonDisabled" @click="crtFastAddCategory()" variant="primary">Изменить</b-button>
          <b-button @click="close()" class="ml-1" variant="danger">Отмена</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  component: {
    ToastificationContent,
  },
  props: ["id"],
  data() {
    return {
      category: {
        name: null,
        is_active: true,
      },
      imageSrc: null,
      iconSrc: null,
      submitButtonDisabled: false,
    };
  },
  methods: {
    uploadFile($event, target){
      let file = $event.target.files[0];
      this[target] = URL.createObjectURL(file);
    },
    getDataById() {
      this.$http
      .get(`addresses/fast/addresses/categories/${this.id}/edit`)
      .then(res => {
        let data = res.data
        this.category.name = data.name
        this.category.is_active = data.is_active == 1 ? true : false
        this.imageSrc = data.icon_by_map
        this.iconSrc = data.icon
      })
      .catch(err => {
        console.log(err);
      })
    },
    crtFastAddCategory() {
      let formData = new FormData()
      let data = JSON.parse(JSON.stringify(this.category))
      data.is_active  = data.is_active ? 1 : 0
      if (this.category.icon) {
        formData.append("icon", this.category.icon);
      }
      if (this.category.icon_by_map) {
        formData.append("icon_by_map", this.category.icon_by_map);
      }
      this.submitButtonDisabled = true;
      this.$http
        .post(`addresses/fast/addresses/categories/${this.id}/update`, formData, {
          params: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.close()
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    close() {
      this.category = {
        name: null,
        is_active: true,
      }
      this.imageSrc = null
      this.iconSrc = null
      this.$bvModal.hide('editFastAddressCategory')
    }
  },
};
</script>
