<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table 
                striped
                small
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="fastAddressCategories"
                @row-dblclicked="editFastAddrCtgr"
                :fields="fields"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>
                <template #cell(icon)="data">
                  <div class="">
                    <img style="width: 25px;" :src="data.item.icon" alt="">
                  </div>
                </template> 
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  props: ["fastAddressCategories"],
  data() {
    return {
      fields: [
        { key: 'checkbox', label: '', thStyle: {width: '30px'} },
        { key: 'id', label: 'ID', thStyle: {width: '30px'} },
        { key: 'name', label: 'Наименование', thStyle: {width: '30px'} },
        { key: 'icon', label: 'Иконка', thStyle: {width: '30px'} },
        { key: 'created_at', label: 'Создано', thStyle: {width: '30px'} },
        { key: 'created_by_login', label: 'Создавший сотрудник', thStyle: {width: '30px'} }
      ]
    }
  },
  methods: {
    editFastAddrCtgr(data) {
      this.$emit("editForm", data.id)
    }
  },
  mounted() {
    resizeable()
  }
}
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-context.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
table {
  outline: none;
  table-layout: fixed;
  overflow: hidden;
  position: relative;
}
tr,
td,
th {
  padding: 0;
}
td,
th {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  min-width: 60px;
}
</style>
 