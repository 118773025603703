<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-fast-address-category @editForm="editForm" :fastAddressCategories="fastAddressCategories"></table-fast-address-category>
      <modal-create @refresh="refresh"></modal-create>
      <modal-edit @refresh="refresh" :id="id"></modal-edit>
    </div>
  </div>
</template>
<script>
import tableFastAddressCategory from '@/views/component/Table/tableFastAddressCategory.vue';
import modalCreate from '@/views/component/Modal/ModalFastAddressCategory/modalCreate.vue'
import modalEdit from '@/views/component/Modal/ModalFastAddressCategory/modalEdit.vue'
export default {
  components: {
    tableFastAddressCategory,
    modalCreate,
    modalEdit
  },
  data() {
    return {
      id: "",
      fastAddressCategories: [],
      showPreloader: false,
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.refresh();
  },
  methods: {
    editForm(id) {
      this.id = id;
      this.$bvModal.show("editFastAddressCategory")
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get("addresses/fast/addresses/categories").then((res) => {
        this.showPreloader = false;
        this.fastAddressCategories = res.data;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.$http.get(`${this.$route.name}`).then((res) => {
          this.fastAddressCategories = res.data;
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
